import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksToken from "components/Header/HeaderLinksToken.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/charlieicoabi.json";
import BUSD from "../ABI/busdabi.json"

const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;

const dashboardRoutes = [];

const providerOptions = {
  connector: async (ProviderPackage, options) => {
    const provider = new ProviderPackage(options);
    await provider.enable()
    return provider;
  }
};

const provider = new WalletConnectProvider({
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    56: "https://bsc-dataseed.binance.org/",
  },
});

const web3Modal = new Web3Modal({
  //  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

const useStyles = makeStyles(styles);
let slider1;
let slider2;
export default function WalletGuide(props) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [currAddress, setcurrentAddress] = React.useState('');
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [charlievalue, setcharlievalue] = React.useState(0);
  const [coin, setcoin] = React.useState('BUSD');
  const [charlieusd, setcharlieusd] = React.useState(0);
  const [show, setshow] = React.useState(false)
  const [userbnbbal, setuserbnbbal] = React.useState(false)
  const [userbusdbal, setuserbusdbal] = React.useState(false)
  const [progress, setprogress] = React.useState(false)

  const classes = useStyles();

  const { ...rest } = props;

  /*------------developer code starts---------------------------------------*/

  async function alertconnect() {
    toastAlert('error', "Connect Wallet", 'excerciseerr');
  }

  async function checkchange(termsandcon) {
    setaccept(termsandcon);
  }

  async function charliechange(value) {
    setcharlievalue(value);
    console.log(value, "========valvalval1char")
    if (value != "" && value != undefined && value != null && parseFloat(value) > 0) {
      if (coin == "BUSD") {
        var usd2 = (parseFloat(value) / parseFloat(charlieusd));
        setcoinvalue(usd2.toFixed(5));
      }
    } else {
      setcoinvalue(0);
    }
  }

  async function inputchange(value) {
    setcoinvalue(value);
    console.log(value, "========valvalval1bnb")
    console.log(coin, "====================================coin")
    if (value != "" && value != undefined && value != null && parseFloat(value) > 0) {
      if (coin == "BUSD") {
        var trxtousdt = (parseFloat(value) * parseFloat(charlieusd));
        setcharlievalue(trxtousdt.toFixed(5));
      }
    } else {
      setcharlievalue(0)
    }
  }

  async function selectchange(e) {
    console.log(e.target.value)
    setcoin(e.target.value)
  }

  async function getcoinvalue() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          const web3 = new Web3(window.web3.currentProvider)
          if (window.web3.currentProvider.isMetaMask === true) {
            var charlieContract = new web3.eth.Contract(ABI, contractAddr);
            var tokenvalue = await charlieContract.methods.getTokenprice().call();
            console.log(tokenvalue, "========tokentoken")
            var tokenusd = parseFloat(tokenvalue) / 1000000
            setcharlieusd(tokenusd)
          }
        } else {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
        }
      } catch (err) {
        toastAlert('error', "Please Add Metamask External", 'excerciseerr');
      }
    }
    else {
      toastAlert('error', "Please Add Metamask External", 'excerciseerr');
    }
  }

  async function getbalance(add) {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(add).call();
    console.log('tokenDethBln', busdBln)
    var value = parseFloat(busdBln) / 1000000000000000000;
    console.log("dethbalance:", busdBln)
    setuserbusdbal(value)
  }

  async function buyfunction() {
    setprogress(true)
    if (typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined) {
      var currAddress = localStorage.getItem('account');
      if (typeof window.web3 != "undefined" && window.web3.currentProvider != 'undefined') {
        if (window.web3.currentProvider.networkVersion != 97) {
          toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
          setprogress(false)
        } else {
          const provider = await web3Modal.connect();
          const web3 = new Web3(provider);
          if (coinvalue != null && coinvalue != undefined && parseFloat(coinvalue) > 0 && coinvalue != "") {
            if (charlievalue != null && charlievalue != undefined && parseFloat(charlievalue) > 0 && charlievalue != "") {
              if (coin == "BUSD") {
                if (parseFloat(coinvalue) < parseFloat(userbusdbal)) {
                  if (accept == true) {
                    var contract = new web3.eth.Contract(ABI, contractAddr);
                    var budcontrct = new web3.eth.Contract(BUSD, busdContractAddr);
                    console.log(contractAddr, (parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString(), "======input")
                    await budcontrct.methods.approve(contractAddr, (parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString())
                      .send({ from: currAddress })
                      .then(async function (result1, error1) {
                        if (result1) {
                          await contract.methods.deposit((parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString())
                            .send({ from: currAddress })
                            .then(async function (result, error) {
                              console.log(result, error, "======================getgetget")
                              if (result) {
                                setprogress(false);
                                toastAlert('success', "Successfully completed", 'excerciseerr');
                                setcoinvalue(0)
                                setcharlievalue(0)
                              }
                            }).catch(function (error) {
                              setprogress(false)
                              toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                              console.log(error, "======================error")
                            })
                        } else {
                          setprogress(false)
                          toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                        }
                      })
                  } else {
                    setprogress(false)
                    toastAlert('error', "Please accept Terms and Condition", 'excerciseerr');
                  }
                } else {
                  setprogress(false)
                  toastAlert('error', "Insufficient Balance", 'excerciseerr');
                }
              }
            } else {
              setprogress(false)
              toastAlert('error', "Invalid Input", 'excerciseerr');
            }
          } else {
            setprogress(false)
            toastAlert('error', "Invalid Input", 'excerciseerr');
          }
        }
      } else {
        setprogress(false)
        toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
      }
    } else {
      setprogress(false)
      toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
    }
  }

  return (
    <div className="header_home">
      <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksToken />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />



      <div className="main whitepaper_section">
        <div className={classes.container + " homepage_container"} >
          <GridContainer className="mt-5 align-items-flex-start">
            <GridItem sm={12} md={10} lg={12} className=" text-center" data-aos="fade-up" data-aos-duration="2000">
              <div class="tab-content token_content">
                <div id="Intro" class="tab-pane fade in active show">
                  <h3>Create a Wallet</h3>
                  <p className="mb-4">To get started on CharlieSwap, the first thing you'll need is to set up a wallet that supports Binance Smart Chain (BSC). Wallets are available both on desktop computers and on smartphone devices. You'll need to choose the wallet that fits your needs best.</p>
                  <h3>When you're setting up a wallet, be sure to:</h3>
                  <ul>
                    <li>✅ Download and install only the latest version from an official source.</li>
                    <li>✅ Follow the setup guide carefully.</li>
                    <li>✅ Safely back up your recovery phrases.</li>
                    <li>❌ NEVER share your recovery phrases with anyone, under any circumstances.</li>
                    <li>❌ NEVER input your recovery phrase to a website or app, other than your wallet app.</li>
                  </ul>
                  <h3>Smartphone/Mobile or Desktop wallet?</h3>
                  <p>Mobile device wallets and desktop-based wallets have different strengths and weaknesses. Consider which fits your needs better to help decide which type of wallet to use.</p>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Mobile</th>
                          <th>Desktop</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Use anywhere</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>
                        <tr>
                          <td className="text-left">Easy to use</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>
                        <tr>
                          <td className="text-left">More secure</td>
                          <td>➖</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Accessibility friendly</td>
                          <td>➖</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Damage/loss/theft resistant</td>
                          <td>➖</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Power/connection outage resistant</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3>Smartphone/Mobile wallets</h3>
                  <p>Smartphone/Mobile wallets allow you to access your crypto almost anywhere. Wallets are available on both Android and iOS devices.</p>
                  <h3 className="mt-4">Which mobile wallet should I choose?</h3>
                  <p>This comparison table gives an overview of the most popular mobile wallets used with CharlieSwap.</p>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Trust Wallet</th>
                          <th>MetaMask</th>
                          <th>Token Pocket</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Binance Smart Chain support</td>
                          <td>✅</td>
                          <td>⚠️</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Built-in DApp browser</td>
                          <td>
                          ✅ Android
                          ⚠️ IOS
                          </td>
                          <td>➖</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Hardware wallet compatible</td>
                          <td>➖</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>
                        <tr>
                          <td className="text-left">Open source (auditability)</td>
                          <td>✅</td>
                          <td>✅</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Interact with BscScan (for advanced users!)</td>
                          <td>➖</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>                        
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-4 mt-4">⚠️ Requires some setup</p>
                  <p className="mb-4">You can find more in-depth information about each wallet below, as well as download links and installation guides.</p>
                  
                  <nav>
                    <div class="nav nav-tabs p-0" id="nav-tab" role="tablist">
                      <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#trust-wallet" role="tab" aria-controls="nav-home" aria-selected="true">Trust Wallet</a>
                      <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#metamask" role="tab" aria-controls="nav-profile" aria-selected="false">MetaMask</a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="trust-wallet" role="tabpanel" aria-labelledby="nav-home-tab">
                      <img src={require("../assets/images/trust-wallet.png")} alt="Wallet" className="img-fluid" />
                      <p>Trust Wallet is a popular wallet for smart devices. It supports DApps, NFT collectibles (with Opensea.io support), and in-wallet staking.</p>
                      <h3>Pros:</h3>
                      <ul>
                        <li>Open source for auditability</li>
                        <li>Backed by the Binance cryptocurrency exchange </li>
                        <li>Easily switch between several crypto networks</li>
                        <li>Includes networks by default</li>
                        <li>Includes in-wallet staking options</li>
                        <li>Has additional features such as NFT collection, BNB staking, and DApp access within the wallet</li>
                      </ul>
                      <h3>Cons:</h3>
                      <ul>
                        <li>Language tied to phone settings</li>
                        <li>Limited DApps </li>
                        <li>Transaction notifications can be delayed</li>
                      </ul>
                      <p><a href="https://trustwallet.com/" target="_blank">Download Trust Wallet</a> (Automatically detects device)</p>
                      <p><a href="https://www.binance.com/en/blog/421499824684901157/how-to-set-up-and-use-trust-wallet-for-binance-smart-chain" target="_blank">Trust Wallet Setup Guide</a></p>
                    </div>
                    <div class="tab-pane fade" id="metamask" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <img src={require("../assets/images/metamask_01.png")} alt="Wallet" className="img-fluid" />
                      <p>MetaMask is a very popular browser-based wallet plugin that supports ERC20 (Ethereum network) and BEP2 & BEP20 (Binance Chain and Binance Smart Chain networks).</p>
                      <h3>​Pros:</h3>
                      <ul>
                        <li>Open source for auditability </li>
                        <li>WEB3 capable on BscScan </li>
                        <li>A strong history of security and reliability</li>
                        <li>A large amount of how-to guides and resources online</li>
                        <li>Many tools and custom settings</li>
                        <li>Offers ETH purchases with Transak </li>
                        <li>Supports a very large number of languages</li>
                        <li>Is also available a browser plugin for desktop devices</li>
                      </ul>
                      <h3>Cons:</h3>
                      <ul>
                        <li>Longer setup time than other options </li>
                        <li>Additional steps needed to use with Binance Smart Chain and CharlieSwap</li>
                        <li>Extra information and settings may confuse beginners</li>
                      </ul>
                      <p><a href="https://metamask.io/download.html" target="_blank">Download MetaMask</a> (Automatically detects device)</p>
                      <p><a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">MetaMask Setup Guide</a></p>
                    </div>
                  </div>    
                  <h3 className="mt-4">Desktop/Web Browser wallets</h3>      
                  <p>Desktop wallets are available on your home computer or laptop computer. Wallets on your computer can run as standalone applications, or as web browser plugins for popular browsers like Chrome and Firefox.</p>        
                  <h3 className="mt-4">Which desktop wallet should I choose?</h3>
                  <p>This comparison table gives an overview of the most popular desktop wallets used with CharlieSwap.</p>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>MetaMask</th>
                          <th>Binance Wallet</th>
                          <th>Token Pocket</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Binance Smart Chain support</td>
                          <td>⚠️</td>
                          <td>✅</td>
                          <td>⚠️</td>                          
                        </tr>
                        <tr>
                          <td className="text-left">Built-in DApp browser</td>
                          <td>➖</td>
                          <td>➖</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Hardware wallet compatible</td>
                          <td>✅</td>
                          <td>✅</td>
                          <td>➖</td>
                        </tr>
                        <tr>
                          <td className="text-left">Open source (auditability)</td>
                          <td>✅</td>
                          <td>✅</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td className="text-left">Interact with BscScan (for advanced users!)</td>
                          <td>✅</td>
                          <td>➖</td>
                          <td>➖</td>
                        </tr>                        
                      </tbody>
                    </table>
                  </div>
                  <p>⚠️ Requires additional setup</p>
                  <p>You can find more in-depth information about each wallet below, as well as download links and installation guides.</p>

                  <nav>
                    <div class="nav nav-tabs p-0" id="nav-tab" role="tablist">
                      <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#metamask-desktop" role="tab" aria-controls="nav-home" aria-selected="true">MetaMask</a>
                      <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#binance-desktop" role="tab" aria-controls="nav-profile" aria-selected="false">Binance Wallet</a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="metamask-desktop" role="tabpanel" aria-labelledby="nav-home-tab">
                      <img src={require("../assets/images/metamask_01.png")} alt="Wallet" className="img-fluid" />
                      <p>MetaMask is a very popular browser-based wallet plugin that supports ERC20 (Ethereum network) by default, and BEP2 & BEP20 (Binance Chain and Binance Smart Chain networks) with a little work.</p>
                      <h3>Pros:</h3>
                      <ul>
                        <li>Open source for auditability </li>
                        <li>WEB3 capable on BscScan </li>
                        <li>A strong history of security and reliability</li>
                        <li>A large amount of how-to guides and resources online  </li>
                        <li>Many tools and custom settings    </li>
                        <li>Supports a very large number of languages  </li>
                        <li>Is also available on mobile devices</li>
                      </ul>
                      <h3>Cons:</h3>
                      <ul>
                        <li>Longer setup time than other options  </li>
                        <li>Additional steps needed to use with Binance Smart Chain and CharlieSwap </li>
                        <li>Extra information and settings may confuse beginners</li>
                      </ul>
                      <p><a href="https://metamask.io/download.html" target="_blank">Download MetaMask</a> (Automatically detects browser)</p>
                      <p><a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">MetaMask Setup Guide</a></p>
                    </div>

                    <div class="tab-pane fade" id="binance-desktop" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <img src={require("../assets/images/binance.png")} alt="Wallet" className="img-fluid" />
                      <p>Binance Wallet is a browser-plugin wallet that natively supports the Binance Chain (BEP2) Binance Smart Chain (BEP20) networks. Binance Wallet was developed by the Binance cryptocurrency exchange.</p>
                      <h3>​Pros:</h3>
                      <ul>
                        <li>Open source for auditability </li>
                        <li>Backed by the Binance cryptocurrency exchange  </li>
                        <li>Can be directly linked to a Binance or Gmail account</li>
                        <li>A streamlined user experience</li>
                        <li>Supports Binance Smart Chain by default, making using CharlieSwap easy</li>
                        <li>Supports a large number of languages</li>
                        <li>Hardware wallet compatible</li>
                      </ul>
                      <h3>Cons:</h3>
                      <ul>
                        <li>Lacks some custom settings of other wallets </li>
                        <li>Lack of additional tools makes some actions available in other wallets impossible</li>
                        <li>Has fewer online resources than more established wallets</li>
                      </ul>
                      <p><a href="https://www.binance.org/en" target="_blank">Download Binance Wallet</a>(Automatically detects device)</p>
                      <p><a href="https://docs.binance.org/smart-chain/wallet/binance.html" target="_blank">Binance Wallet Setup Guide</a></p>
                    </div>
                  </div> 
                </div>
              </div>
            </GridItem>
          </GridContainer>






        </div>

        <FooterHome />
      </div>
    </div>
  );
}
