import React,{useState,useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksHome from "components/Header/HeaderLinksHome.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/charlieicoabi.json";
import BUSD from "../ABI/busdabi.json"

const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;

const Icon = styled(props => (
  <div {...props}>
    <div className="minus"><ExpandLess/></div>
    <div className="plus"><ExpandMore/></div>
  </div>
))`
  & > .plus {
    display: block;
    color: #fd7e14;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #fff;
    font-size: 32px;
    transform: rotate(180deg);
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const providerOptions = {
   connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);
        await provider.enable()
        return provider;
    }
  };

  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });

  const web3Modal = new Web3Modal({
   //  network: "mainnet", // optional
     cacheProvider: true, // optional
     providerOptions // required
  });

const useStyles = makeStyles(styles);
let slider1;
let slider2;
export default function LandingPage(props) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [currAddress, setcurrentAddress] = React.useState('');
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [charlievalue, setcharlievalue] = React.useState(0);
  const [coin, setcoin] = React.useState('BUSD');
  const [charlieusd, setcharlieusd] = React.useState(0);
  const [show, setshow] =  React.useState(false)
  const [userbnbbal, setuserbnbbal] =  React.useState(false)
  const [userbusdbal, setuserbusdbal] =  React.useState(false)
  const [progress, setprogress] =  React.useState(false)

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [navData,setNavData] = useState({
    nav1: null,
    nav2: null
  })

  useEffect(()=>{
    getcoinvalue()
    setNavData({
      nav1: slider1,
      nav2: slider2
    })
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined){
    var currAddress = localStorage.getItem('account');
    setcurrentAddress(currAddress);
    getbalance(currAddress);
    if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
       if (window.web3.currentProvider.networkVersion != 56) {
         setcurrentAddress("");
         toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
       }
     }else{
       toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
     }
    }
  },[userbusdbal])
  const classes = useStyles();

  const slickSettingsVerticalNav = {
    arrows: true,
    vertical: true,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    verticalSwiping: true,
    ref: slider => (slider2 = slider),
    asNavFor: navData.nav1
    // asNavFor: this.state.nav2,
    // ref: slider => (this.slider1 = slider),
  }

  const slickSettingsVerticalMain = {
    arrows: false,
    slidesToShow: 1,
    asNavFor: navData.nav2,
    ref: slider => (slider1 = slider)
    // asNavFor: this.state.nav1,
    // ref: slider => (this.slider2 = slider),
  }

  // Banner Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Mins</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span>
      </div>
    );
  };

  const { ...rest } = props;

  var team_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      }
    ]
  };

  var roadmap_settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  /*------------developer code starts---------------------------------------*/

  async function alertconnect(){
    toastAlert('error', "Connect Wallet", 'excerciseerr');
  }

  async function checkchange(termsandcon){
    setaccept(termsandcon);
  }

  async function charliechange(value){
    setcharlievalue(value);
    console.log(value,"========valvalval1char")
    if(value!="" && value!=undefined  && value!=null && parseFloat(value)>0){
      if(coin=="BUSD"){
        var usd2 =(parseFloat(value)/parseFloat(charlieusd));
        setcoinvalue(usd2.toFixed(5));
      }
    }else{
     setcoinvalue(0);
    }
  }

  async function inputchange(value){
    setcoinvalue(value);
    console.log(value,"========valvalval1bnb")
    console.log(coin,"====================================coin")
    if(value!="" && value!=undefined  && value!=null && parseFloat(value)>0){
     if(coin=="BUSD"){
      var trxtousdt =(parseFloat(value)*parseFloat(charlieusd));
      setcharlievalue(trxtousdt.toFixed(5));
    }
   }else{
    setcharlievalue(0)
   }
  }

  async function selectchange(e){
    console.log(e.target.value)
    setcoin(e.target.value)
  }

  async function getcoinvalue(){
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
           const web3 = new Web3(window.web3.currentProvider)
           if (window.web3.currentProvider.isMetaMask === true) {
             var charlieContract = new web3.eth.Contract(ABI,contractAddr);
             var tokenvalue = await charlieContract.methods.getTokenprice().call();
             console.log(tokenvalue,"========tokentoken")
             var tokenusd = parseFloat(tokenvalue)/1000000
              setcharlieusd(tokenusd)
            }
        } else {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
        }
      } catch (err) {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
      }
    }
    else {
      toastAlert('error', "Please Add Metamask External", 'excerciseerr');
    }
  }

  async function getbalance(add){
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(add).call();
    console.log('tokenDethBln',busdBln)
    var value = parseFloat(busdBln) / 1000000000000000000;
    console.log("dethbalance:",busdBln)
    setuserbusdbal(value)
  }

  function convert(n){
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead,decimal,pow] = n.toString()
            .replace(/^-/,"")
            .replace(/^([0-9]+)(e.*)/,"$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }


  async function buyfunction(){
    setprogress(true)
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined){
    var currAddress = localStorage.getItem('account');
    if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
       if (window.web3.currentProvider.networkVersion != 56) {
         toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
         setprogress(false)
       }else{
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        if(coinvalue!=null && coinvalue!=undefined && parseFloat(coinvalue)>0 && coinvalue!=""){
          if(charlievalue!=null && charlievalue!=undefined && parseFloat(charlievalue)>0 && charlievalue!=""){
           if(coin == "BUSD"){
            if(parseFloat(coinvalue)<parseFloat(userbusdbal)){
              if(accept==true){
              var contract = new web3.eth.Contract(ABI, contractAddr);
              var budcontrct =  new web3.eth.Contract(BUSD, busdContractAddr);
              var converttodigit = coinvalue * 1000000000000000000;
              var converttodigitfinal = convert(converttodigit);
              console.log(converttodigitfinal,"converttodigitfinal")
              await budcontrct.methods.approve(contractAddr,converttodigitfinal.toString())
              .send({ from: currAddress })
              .then(async function (result1,error1) {
              if(result1){
              await contract.methods.deposit(converttodigitfinal.toString())
              .send({ from: currAddress })
              .then(async function (result,error) {
              console.log(result,error,"======================getgetget")
              if(result){
                setprogress(false);
                toastAlert('success', "Successfully completed", 'excerciseerr');
                setcoinvalue(0)
                setcharlievalue(0)
              }
              }).catch(function(error){
                setprogress(false)
                toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                console.log(error,"======================error")
              })
             }else{
              setprogress(false)
              toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
             }
             })
             }else{
              setprogress(false)
              toastAlert('error', "Please accept Terms and Condition", 'excerciseerr');
             }
             }else{
               setprogress(false)
               toastAlert('error', "Insufficient Balance", 'excerciseerr');
             }
           }
         }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
         }
        }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
        }
       }
     }else{
       setprogress(false)
       toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
     }
    }else{
      setprogress(false)
      toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
    }
  }

  return (
    <div className="header_home">
      <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksHome />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />

      <div className="page-header header-filter" name="home">
        <div className={classes.container + " homepage_container"} >
          <GridContainer className="mt-5 align-items-center">
            <GridItem sm={12} md={10} lg={11} className="m-auto text-center" data-aos="fade-up" data-aos-duration="2000">
              {/* <h1>Join Our Charlie Army! Let's Take Charlie To The Moon!</h1> */}
              <h2>Join Our Charlie Army! Let's Take CHOCO To The Moon!</h2>
              <form className="auth_form px-3 pt-4 pb-0">
                <div className="form-row">
                  <div className="form-group col-md-12 col-lg-6">
                    <div className="form_text_prepend">
                      <input type="text" className="auth_inp"disabled={show!=true? "":"true"} id="coinvalue" value={coinvalue} onChange={(e) => {inputchange(e.target.value)}} placeholder="Enter Amount to Buy"/>
                      <span className="custom-select select_span" onChange={selectchange}>
                        <option selected value="BUSD">BUSD</option>
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-md-12 col-lg-6">
                    <div className="form_text_prepend">
                      <input type="number" className="auth_inp" disabled={show!=true? "":"true"} id="charlievalue" value={charlievalue} placeholder="You Will Get"/>
                      <span>CHOCO</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="form-group">
                  <div className="checkbox">
                      <label>
                          <input type="checkbox" value="" onChange={(e) => {checkchange(e.target.checked)}} />
                          <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                          <span className="tcLabelText">I accept and agree the <Link to="/terms" className="text_red">terms and conditions</Link></span>
                      </label>
                  </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button type="button" variant="contained" className="primary_btn btnOrange mb-4" onClick={() =>{ buyfunction()}}><span className="btnTextIcon"><span>Swap to CHOCO</span> <i className="fas fa-angle-double-right"></i></span></Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      <div className="main">

        <div className="blue_bg_section">

          {/* About Section */}
          <div className="about_section" name="about">
            <div className="container">
              <div className="section">
                <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                  <GridItem sm={12} md={12} lg={12}>
                    <h1 className="main_title">About CHOCO</h1>
                    <p className="home_para">CharlieSwap is a decentralized exchange for swapping BEP20 tokens on Binance Smart Chain.</p>
                    <p className="home_para">CharlieSwap uses an automated market maker (AMM) model where users trade against a liquidity pool. Such pools are filled with user's funds. They deposit them into the pool, receiving liquidity provider (or LP) tokens in return. They can use those tokens to reclaim their share , plus a portion of the trading fees.</p>
                    <p className="home_para">CharlieSwap also allows users to farm additional governance tokens - CHOCO</p>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Token Section */}
        <div className="section" name="ico">
          <div className="container">
            <div className="token_sale_section">
              <GridContainer className="justify-content-center" data-aos="fade-up" data-aos-duration="1000">
                <GridItem sm={12} md={6} lg={6}>
                  <h1 className="main_title">IDO Details</h1>
                  <div className="ico_details_panel">
                    <div>
                      <p>Token Ticker</p>
                      <h2>CHOCO</h2>
                    </div>
                    <div>
                      <p>Token</p>
                      <h2>Chocolate Token</h2>
                    </div>
                  </div>

                  <div className="ico_details_panel">
                    <div>
                      <p>Total Number of Tokens</p>
                      <h2>100,000,000,000 </h2>
                    </div>
                    <div>
                      <p>Price In BUSD </p>
                      {/* <h2>250,000,000</h2> */}
                      <h4>10.5 BUSD = 1 Token</h4>
                    </div>
                  </div>

                  <div className="ico_details_panel">
                    <div>
                      <p>Soft Cap</p>
                      <h2>1,000,000 BUSD</h2>
                    </div>
                    <div>
                      <p>Hard Cap</p>
                      <h2>5,000,000 BUSD</h2>
                    </div>
                  </div>

                  {/* <div className="ico_details_panel">
                    <div>
                      <p>1 ETH = 400 USD (approx.) </p>
                      <h2>400 USD</h2>
                    </div>
                    <div>
                      <p>Price of one Token in USD </p>
                      <h2>0.1</h2>
                    </div>
                  </div> */}
                </GridItem>
                <GridItem sm={12} md={6} lg={6}>
                  <h1 className="main_title">Transparent Tokenomics</h1>

                  <div className="ico_details_panel">
                    <div>
                      <p>Launch price</p>
                      <h2>5.0000(BUSD)</h2>
                    </div>
                    <div>
                      <p>Total Supply</p>
                      <h2>100,000,000,000 CHOCO</h2>
                    </div>
                  </div>
                  <div className="ico_details_panel">
                    <div>
                      <p>Total Circulating Supply of 0.0001%</p>
                      <h2>(1,000,000 CHOCO) At launch</h2>
                    </div>

                  </div>

                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        {/* Roadmap Section */}
        <div className="roadmap_section section" name="roadmap">
          <div className="container">
            <div className="text-center">
              <GridContainer>
                <GridItem sm={12} md={12} lg={12} className="m-auto" data-aos="fade-up" data-aos-duration="2000">
                  <h1 className="main_title main_title_center">Road Map</h1>
                  <p className="home_para">All data is stored on the blockchain in the public domain and can be verified!</p>
                  <GridContainer>
                    <GridItem sm={12} md={12} lg={12}>
                      <div className="roadmap_carousel">
                        <Slider {...roadmap_settings}>
                          <div>
                            <div className="roadmap_carousel_div">
                            <ul>
                                <li>Custom Contracts built with Chocolate LP V1</li>
                                <li>AMM gold antibot Framework Integration</li>
                                <li>NFTokenomics Game Theory Creation</li>
                              </ul>

                            </div>
                          </div>
                          <div>
                            <div className="roadmap_carousel_div">
                            <ul>
                                <li>Charlie Governance protocol V3 launch</li>
                                <li>Info website launch</li>
                                <li>First contract NFT drop</li>
                                <li>CMC & Coingecko listings</li>
                                <li>Evidence submission website</li>
                                <li>AMPO (Automatic Market Price Order) V1 launch</li>
                              </ul>


                            </div>
                          </div>
                          <div>
                            <div className="roadmap_carousel_div">
                            <ul>

                                <li>CHOCO Token Vaults</li>
                                <li>NFT Gallery V1</li>
                                <li>First Evidence Submission Contest (Reoccurring)</li>
                                <li>Exchange Listings</li>
                              </ul>

                            </div>
                          </div>
                          <div>
                            <div className="roadmap_carousel_div">
                            <ul>
                                <li>3D Marketplace built on Unity/Unreal game engine with dAPP Integration</li>
                                <li>3D NFT Galley V1</li>
                                <li>iExec Off-Chain Clound Integration</li>
                                <li>Multi-Chain Integration</li>
                              </ul>
                            </div>
                          </div>
                        </Slider>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        {/* Team Section */}
        <div className="team_section section" name="team">
          <div className="container">
            <div className="text-center">
              <GridContainer data-aos="fade-up" data-aos-duration="1000">
                <GridItem sm={12} md={12} lg={12} className="m-auto">
                  <h1 className="main_title main_title_center">Our Team</h1>
                </GridItem>
              </GridContainer>
              <GridContainer data-aos="fade-up" data-aos-duration="1000">
                <GridItem xs={12} sm={12} md={12}>
                  <div className="slick_carousel_custom">
                    <Slider {...team_settings}>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_01.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Jeremi Doe</h2>
                            <h4>Chief Executive Officer</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_02.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Eddy Hochard</h2>
                            <h4>Chief Executive Officer</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_03.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Jeremi Doe</h2>
                            <h4>Chief Executive Officer</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_04.jpg")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Eddy Hochard</h2>
                            <h4>Chief Executive Officer</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_05.png")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Stuard Black</h2>
                            <h4>Business Developmnent Insurance</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="team_panel">
                          <div className="team_img">
                            <img src={require("../assets/images/team_06.png")} alt="Team" className="img-fluid" />
                          </div>
                          <div className="team_details">
                            <h2>Melina Thomas</h2>
                            <h4>Global Marketing Officer</h4>
                            {/* <ul>
                              <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                              <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        {/* <div className="faq_section" name="faq">
          <div className="container">
            <div className="section text-center">
              <GridContainer>
                <GridItem sm={12} md={10} lg={10} className="m-auto" data-aos="fade-up" data-aos-duration="1000">
                  <h1 className="main_title main_title_center">FAQ</h1>
                  <div className="faq_setion_panel mt-5">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <h2 className="accordian_head">What is Charlie Token?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header">
                        <h2 className="accordian_head">How to buy this ICO? </h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFaq('panel3')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel3bh-content" id="panel3bh-header">
                        <h2 className="accordian_head">What cryptocoins supports? </h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFaq('panel4')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel4bh-content" id="panel4bh-header">
                        <h2 className="accordian_head">How to transfer the tokens?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChangeFaq('panel5')}>
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel5bh-content" id="panel5bh-header">
                        <h2 className="accordian_head">Can I transfer the token after puchase?</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="accordian_para">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure enderit. Fusce ut velit laoreet, tempus arcu eu, molestie tortor. Nam vel justo cursus, faucibus lorem eget, egestas eros.</p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </GridItem>
              </GridContainer>

             <GridContainer>
                <GridItem sm={12} md={10} lg={8} className="m-auto">
                  <div className="footer_contact_form" name="contact">
                    <form>
                      <h2>Contact Us</h2>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <input type="text" className="auth_inp" id="footer_name" placeholder="Your Name"/>
                        </div>
                        <div className="form-group col-md-12">
                          <input type="text" className="auth_inp" id="footer_subject" placeholder="Subject"/>
                        </div>
                        <div className="form-group col-md-12">
                          <textarea className="auth_inp" id="footer_message" placeholder="Message" rows="4"></textarea>
                        </div>
                      </div>
                      <Button className="primary_btn">Submit</Button>
                    </form>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div> */}

        <FooterHome />
      </div>
    </div>
  );
}
