import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './index.css';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// pages for this product
import Home from "views/home.js";
import Whitepaper from "./views/whitepaper.js"
import TokenDetails from "./views/token-details"
import WalletGuide from "./views/wallet-guide"

ReactDOM.render(
  <BrowserRouter basename="/">
  <ToastContainer />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/introduction" exact component={Whitepaper} />
      <Route path="/token-details" exact component={TokenDetails} />
      <Route path="/wallet-guide" exact component={WalletGuide} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
