let NODE_ENV = 'local';

let Front_URL = '';
let decimalvalues = 1000000000000000000;
let toFixed = 5;
let adminAddress = "0x152ee3b317CDc27F6869AB91bFD941A4ecE8bE1B"
let chocoacontract = "0xdbae1687a8f5773b76306d99594623b255180830"
let busdcontract = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
let charlieicocontract = "0xa5D9847001C1A715f940f63eC1C0e6dD0f48e6cd"
// let adminAddress = "0x373F886904048975034488320E37d171E092C135"
// let chocoacontract = "0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77"
// let busdcontract = "0x8ce7720fd183aec96b676fd8250724b05b0d7a6f"
// let charlieicocontract = "0x59f8182d7b0629ee4Efb4bE5e737dd15FbFbf9E0"

let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}

let networkVersion = '56';

if (NODE_ENV === "production") {
  Front_URL = 'https://ido.charlieswap.org/';
}
else if (NODE_ENV === "demo") {
    Front_URL = '';
}
else {
    Front_URL = 'https://ido.charlieswap.org/';
}

let key = {
    Front_URL: Front_URL,
    decimalvalues:decimalvalues,
    toFixed:toFixed,
    networkVersion: networkVersion,
    toasterOption: toasterOption,
    adminAddress: adminAddress,
    charlieicocontract: charlieicocontract,
    chocoacontract: chocoacontract,
    busdcontract: busdcontract,
};

export default key;
