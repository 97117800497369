/*eslint-disable*/
import React, { useState, useEffect,useRef } from "react";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import { toastAlert } from "../../helper/toastAlert";
import { Link } from 'react-scroll'
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [accounts, setaccount]     = React.useState();

  async function disconnect() {
    setaccount("");
    localStorage.clear();
  }

  const providerOptions = {
   connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);
        await provider.enable()
        return provider;
    }
  };

  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });

  const web3Modal = new Web3Modal({
   //  network: "mainnet", // optional
     cacheProvider: true, // optional
     providerOptions // required
  });


  useEffect(() => {
    var web3 = new Web3(window.ethereum);
    if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined ){
      connectClick();
    }
    window.ethereum.on('accountsChanged', function (accounts) {
      connectClick();
    })
     window.ethereum.on('networkChanged', function(networkId){
       connectClick();
     });
   }else{
    console.log("inin last")
     toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
   }
  }, []);

  async function connectClick() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const getaccounts = await web3.eth.getAccounts();
    web3.eth.net.getId().then(res =>{
      if(res === 56){
         toastAlert('success', "Connected Wallet", 'excerciseerr');
         setaccount(getaccounts[0]);
         console.log(accounts,"account");
         localStorage.setItem('account',getaccounts[0]);
      }else{
        console.log("ininconncectclince===")
        toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
        setaccount("")
        return false;
      }
    });
  }



  return (
    <div>
      <div className="top_navbar">
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Link to="home" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Home</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="about" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">About</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="ico" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">IDO</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="roadmap" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Roadmap</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="team" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Team</Link>
          </ListItem>
          {/* <ListItem className={classes.listItem}>
            <Link to="faq" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">FAQ</Link>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Link to="contact" spy={true} smooth={true} duration={250} color="transparent" className="nav-link top_nav_login">Contact</Link>
          </ListItem> */}
          <ListItem className={classes.listItem}>
            {/* <a color="transparent" className="nav-link top_nav_login" href={require("assets/pdf/whitepaper_01.pdf")} target="_blank">
              Whitepaper
            </a>  */}
            <a color="transparent" className="nav-link top_nav_login" href="introduction">
              Introduction
            </a>
          </ListItem>
          {accounts ? (
            <ListItem className={classes.listItem}>
              <Button variant="contained" className="menu_btn" onClick={disconnect}><p className="address_btn">{accounts}</p></Button>
            </ListItem>
          ) : (
            <ListItem className={classes.listItem}>
               <Button variant="contained" className="menu_btn" data-toggle="modal" data-target="#connect_wallet_modal">Connect Wallet</Button>
             </ListItem>
          )}

        </List>
      </div>


    </div>
  );
}
