import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import ListItem from "@material-ui/core/ListItem";

import { Link } from "react-router-dom";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import $ from "jquery";
import { toastAlert } from "../../helper/toastAlert";

const useStyles = makeStyles(styles);

export default function Header(props) {

  const providerOptions = {
   connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);

        await provider.enable()

        return provider;
    }
  };

  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });

  const web3Modal = new Web3Modal({
   //  network: "mainnet", // optional
     cacheProvider: true, // optional
     providerOptions // required
  });

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [accounts, setaccount]     = React.useState();
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <ListItem className="logo_div"><a color="transparent" className="nav-link top_nav_login" href="https://charlieswap.org/">
    {brand}
  </a></ListItem>;

  async function connectTrustWallet(){
      await provider.enable();
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      setaccount(accounts);
      console.log(accounts,"account");
      localStorage.setItem('account',accounts);
      $(".closeModal").click();
      window.location.reload()
  }

  async function connectClick() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const getaccounts = await web3.eth.getAccounts();
    web3.eth.net.getId().then(res =>{
      if(res === 56){
         toastAlert('success', "Connected Wallet", 'excerciseerr');
         setaccount(getaccounts[0]);
         console.log(accounts,"account");
         localStorage.setItem('account',getaccounts[0]);
         window.location.reload()
      }else{
        toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
        setaccount("")
        return false;
      }
    });
  }

  return (
    <div>
      <AppBar className={appBarClasses}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden smDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
          </div>
          <Hidden smDown implementation="css">
            {rightLinks}
          </Hidden>
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper + " mobile_nav"
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>

       {/* Connect Wallet Modal */}
      <div className="modal fade" id="connect_wallet_modal" tabindex="-1" aria-labelledby="connect_walletModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">Connect Wallet</h5>
              <button type="button" className="close closeModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button type="button" className="connect_wallet_button" onClick={connectClick}>
                      <img src={require("../../assets/images/metamask.png")} height="28" width="28" className="mr-1 v-align-middle" /> MetaMask
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
