/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses + " footer_home"}>
      <div className={classes.container}>
        <div className="footer_bottom_home">
          {/* <div className="text-left">
            <div className="copyright_txt_inner m-0">
              Copyright {" "}&copy;{" "}{1900 + new Date().getYear()}{" "}
              <Link to="/" className={aClasses}>Chance$,</Link> All Rights Reserved
            </div>
          </div> */}
          <div className="login_social_links">
            <div className="copyright_txt mt-3">Copyright © {" "}{1900 + new Date().getYear()}{" "}<Link to="/" className={aClasses}>CharlieSwap</Link>.
              {" "}All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
