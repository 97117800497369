import React,{useState,useEffect} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksWhitepaper from "components/Header/HeaderLinksWhitepaper.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/charlieicoabi.json";
import BUSD from "../ABI/busdabi.json"

const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;

const Icon = styled(props => (
  <div {...props}>
    <div className="minus"><ExpandLess/></div>
    <div className="plus"><ExpandMore/></div>
  </div>
))`
  & > .plus {
    display: block;
    color: #fd7e14;
    font-size: 32px;
  }
  & > .minus {
    display: none;
    color: #fff;
    font-size: 32px;
    transform: rotate(180deg);
  }
  .Mui-expanded & > .minus {
    display: block;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const providerOptions = {
   connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);
        await provider.enable()
        return provider;
    }
  };

  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });

  const web3Modal = new Web3Modal({
   //  network: "mainnet", // optional
     cacheProvider: true, // optional
     providerOptions // required
  });

const useStyles = makeStyles(styles);
let slider1;
let slider2;
export default function Whitepaper(props) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [currAddress, setcurrentAddress] = React.useState('');
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [charlievalue, setcharlievalue] = React.useState(0);
  const [coin, setcoin] = React.useState('BUSD');
  const [charlieusd, setcharlieusd] = React.useState(0);
  const [show, setshow] =  React.useState(false)
  const [userbnbbal, setuserbnbbal] =  React.useState(false)
  const [userbusdbal, setuserbusdbal] =  React.useState(false)
  const [progress, setprogress] =  React.useState(false)

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [navData,setNavData] = useState({
    nav1: null,
    nav2: null
  })

  useEffect(()=>{
    getcoinvalue()
    setNavData({
      nav1: slider1,
      nav2: slider2
    })
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined){
    var currAddress = localStorage.getItem('account');
    setcurrentAddress(currAddress);
    if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
       if (window.web3.currentProvider.networkVersion != 56) {
         setcurrentAddress("");
         toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
       }else{
        getbalance(currAddress);
       }
     }else{
       toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
     }
    }
  },[])
  const classes = useStyles();

  const slickSettingsVerticalNav = {
    arrows: true,
    vertical: true,
    slidesToShow: 4,
    swipeToSlide: true,
    focusOnSelect: true,
    verticalSwiping: true,
    ref: slider => (slider2 = slider),
    asNavFor: navData.nav1
    // asNavFor: this.state.nav2,
    // ref: slider => (this.slider1 = slider),
  }

  const slickSettingsVerticalMain = {
    arrows: false,
    slidesToShow: 1,
    asNavFor: navData.nav2,
    ref: slider => (slider1 = slider)
    // asNavFor: this.state.nav1,
    // ref: slider => (this.slider2 = slider),
  }

  // Banner Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">Days</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">Hours</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">Mins</span></span>
        <span className="timer_dots">:</span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">Secs</span></span>
      </div>
    );
  };

  const { ...rest } = props;

  var team_settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        }
      }
    ]
  };

  var roadmap_settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  /*------------developer code starts---------------------------------------*/

  async function alertconnect(){
    toastAlert('error', "Connect Wallet", 'excerciseerr');
  }

  async function checkchange(termsandcon){
    setaccept(termsandcon);
  }

  async function charliechange(value){
    setcharlievalue(value);
    console.log(value,"========valvalval1char")
    if(value!="" && value!=undefined  && value!=null && parseFloat(value)>0){
      if(coin=="BUSD"){
        var usd2 =(parseFloat(value)/parseFloat(charlieusd));
        setcoinvalue(usd2.toFixed(5));
      }
    }else{
     setcoinvalue(0);
    }
  }

  async function inputchange(value){
    setcoinvalue(value);
    console.log(value,"========valvalval1bnb")
    console.log(coin,"====================================coin")
    if(value!="" && value!=undefined  && value!=null && parseFloat(value)>0){
     if(coin=="BUSD"){
      var trxtousdt =(parseFloat(value)*parseFloat(charlieusd));
      setcharlievalue(trxtousdt.toFixed(5));
    }
   }else{
    setcharlievalue(0)
   }
  }

  async function selectchange(e){
    console.log(e.target.value)
    setcoin(e.target.value)
  }

  async function getcoinvalue(){
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
           const web3 = new Web3(window.web3.currentProvider)
           if (window.web3.currentProvider.isMetaMask === true) {
             var charlieContract = new web3.eth.Contract(ABI,contractAddr);
             var tokenvalue = await charlieContract.methods.getTokenprice().call();
             console.log(tokenvalue,"========tokentoken")
             var tokenusd = parseFloat(tokenvalue)/1000000
              setcharlieusd(tokenusd)
            }
        } else {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
        }
      } catch (err) {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
      }
    }
    else {
      toastAlert('error', "Please Add Metamask External", 'excerciseerr');
    }
  }

  async function getbalance(add){
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(add).call();
    console.log('tokenDethBln',busdBln)
    var value = parseFloat(busdBln) / 1000000000000000000;
    console.log("dethbalance:",busdBln)
    setuserbusdbal(value)
  }

  async function buyfunction(){
    setprogress(true)
    if(typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined){
    var currAddress = localStorage.getItem('account');
    if(typeof window.web3!= "undefined" && window.web3.currentProvider != 'undefined'){
       if (window.web3.currentProvider.networkVersion != 56) {
         toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
         setprogress(false)
       }else{
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        if(coinvalue!=null && coinvalue!=undefined && parseFloat(coinvalue)>0 && coinvalue!=""){
          if(charlievalue!=null && charlievalue!=undefined && parseFloat(charlievalue)>0 && charlievalue!=""){
           if(coin == "BUSD"){
            if(parseFloat(coinvalue)<parseFloat(userbusdbal)){
              if(accept==true){
              var contract = new web3.eth.Contract(ABI, contractAddr);
              var budcontrct =  new web3.eth.Contract(BUSD, busdContractAddr);
              console.log(contractAddr,(parseInt(parseFloat(coinvalue)*config.decimalvalues)).toString(),"======input")
              await budcontrct.methods.approve(contractAddr,(parseInt(parseFloat(coinvalue)*config.decimalvalues)).toString())
              .send({ from: currAddress })
              .then(async function (result1,error1) {
              if(result1){
              await contract.methods.deposit((parseInt(parseFloat(coinvalue)*config.decimalvalues)).toString())
              .send({ from: currAddress })
              .then(async function (result,error) {
              console.log(result,error,"======================getgetget")
              if(result){
                setprogress(false);
                toastAlert('success', "Successfully completed", 'excerciseerr');
                setcoinvalue(0)
                setcharlievalue(0)
              }
              }).catch(function(error){
                setprogress(false)
                toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                console.log(error,"======================error")
              })
             }else{
              setprogress(false)
              toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
             }
             })
             }else{
              setprogress(false)
              toastAlert('error', "Please accept Terms and Condition", 'excerciseerr');
             }
            }else{
              setprogress(false)
              toastAlert('error', "Insufficient Balance", 'excerciseerr');
            }
           }
         }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
         }
        }else{
          setprogress(false)
          toastAlert('error', "Invalid Input", 'excerciseerr');
        }
       }
     }else{
       setprogress(false)
       toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
     }
    }else{
      setprogress(false)
      toastAlert('error', "Connect BSC Mainnet", 'excerciseerr');
    }
  }

  return (
    <div className="header_home">
      <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksWhitepaper />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />



      <div className="main whitepaper_section">
      <div className={classes.container + " homepage_container"} >
      <GridContainer className="mt-5 align-items-flex-start">
            <GridItem sm={12} md={10} lg={4} className=" text-center">
            <ul class="nav nav-tabs ul_headeing fixed_content">
              <li class="active"><a data-toggle="tab" href="#Intro" className="active">CharlieSwap Intro <i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Started" >Get Started<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Exchange">Exchange<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Swaps">Token Swaps<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Trade">How to Trade<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Liquidity">Liquidity Pools<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Remove_add">How to Add/Remove Liquidity<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Yield">Yield Farming<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Farms_a">How to Use Farms<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#CACAO">CACAO Pools<i class="fas fa-chevron-right"></i></a></li>
              <li><a data-toggle="tab" href="#Stake">How to Stake in CACAO Pools<i class="fas fa-chevron-right"></i></a></li>
            </ul>
              </GridItem>
              <GridItem sm={12} md={10} lg={8} className=" text-center" data-aos="fade-up" data-aos-duration="2000">
              <div class="tab-content">
                  <div id="Intro" class="tab-pane fade in active show">
                    <h3>CharlieSwap Intro</h3>
                    <p>CharlieSwap Intro</p>
                    <p>CharlieSwap helps you make the most out of your crypto in Two ways:</p>
                    <p>Trade and Earn.</p>
                    <div className="brak_space"></div>
                    <p>Trade</p>
                    <p>Instantly swap crypto tokens: no registration or account needed.</p>
                    <div className="brak_space"></div>
                    <p>The people's choice</p>
                    <p>CharlieSwap is the leading decentralized exchange on Binance Smart Chain, with the most legitly trading volumes in the market</p>
                    <div className="brak_space"></div>
                    <p>Low fees</p>
                    <p>Why pay more? CharlieSwap runs on Binance Smart Chain, a blockchain with much lower transaction costs than Ethereum or Bitcoin. </p>
                    <div className="brak_space"></div>
                    <p>Trading fees are lower than other top decentralized exchanges too, so that's a double win for you!</p>
                    <div className="brak_space"></div>
                    <p>Decentralized</p>
                    <p>Trade directly from your wallet app. </p>
                    <p>Unlike centralized exchanges like Binance or Coinbase, CharlieSwap doesn’t hold your funds when you trade: you have 100% ownership of your own crypto. </p>
                    <div className="brak_space"></div>
                    <p>Earn</p>
                    <p>Earn  CHOCO and other tokens for free with super high interest rates.</p>
                    <div className="brak_space"></div>
                    <p>Earn tokens with CACAO Pools</p>
                    <p>Stake CHOCO, earn free tokens. It’s really that easy. </p>
                    <p>CHOCO holders right now are earning tens of millions of USD worth of free tokens each week from major projects. New projects join the party every day, so you can earn more, for even longer.</p>
                    <div className="brak_space"></div>
                      <p>Earn CHOCO with Yield Farms</p>
                      <p>Stake LP tokens, earn CHOCO. You take on a little more exposure to market fluctuations than with the CACAO Pools, but can earn higher APR to offset the risk.</p>
                      <div className="brak_space"></div>
                      <p>Earn Trading Fees</p>
                      <p>No farm? No problem. Even if your trading pair isn’t supported on the Farms page, you can still earn trading fees when you stake your tokens in Liquidity Pools (LPs).</p>
                    <div className="brak_space"></div>
                    <p>Is CharlieSwap safe?</p>
                    <p>See for yourself:</p>
                    <div className="brak_space"></div>
                    <p>Transparent:</p>
                    <p>We’re built on open-source software: our site and all our Smart Contracts are publicly visible for maximum transparency.
                    Our contracts are verified on BscScan so you know that what you see is what you get:</p>
                    <div className="brak_space"></div>
                    <p>Security best practices:</p>
                    <p>The chefs use multisig for all contracts.</p>
                    <p>Our contracts’ time-lock gives you peace of mind.</p>
                    <div className="brak_space"></div>
                    <p>Sounds like fun?</p>
                    <p>Then get flipping!</p>


                  </div>
                  <div id="Started" class="tab-pane fade">
                    <h3>Get Started</h3>
                    <p>Get Started</p>
                    <p><br></br></p>
                    <p>Using anything new can be a bit of a challenge. Don't worry though, we've created a range of guides to help you get comfortable with CharlieSwap!</p>
                    <div><br /></div>
                    <p>Setting up CharlieSwap</p>
                    <div><br /></div>
                    <p>Follow these guides to get everything set up to use CharlieSwap, or feel free to jump to the guide you need if you've been doing okay but lost your way.</p>
                    <div><br /></div>

                    <p><a href="/wallet-guide">Wallet Guide</a></p>

                    <div><br /></div>
                    <p><a href="/token-details">Bep20 Guide</a></p>

                  </div>
                  <div id="Exchange" class="tab-pane fade">
                    <h3>Exchange</h3>
                    <p>Exchange</p>
                    <div><br /></div>
                    <p>CharlieSwap is an Automated Market Maker (AMM), and the Exchange is at the heart of CharlieSwap. CharlieSwap is the leading AMM on the Binance Smart Chain, and as statistics tell, the most ligitly Decentralized Exchange (DEX) ever!</p>
                    <div><br /></div>
                    <p>The CharlieSwap Exchange offers several features that support decentralized trading:</p>
                    <div><br /></div>
                    <p>Swapping/Trading</p>
                    <p>CharlieSwap lets users trade without the need to go through a Centralized Exchange. Everything you do on CharlieSwap is routed directly through your own wallet—no need to trust someone else with your coins!</p>
                    <div><br /></div>
                    <p>Liquidity Pools</p>
                    <p>You can only swap tokens on CharlieSwap if there is enough liquidity for those tokens. If nobody has added much liquidity for the token or tokens you want to swap, it will be difficult, expensive, or impossible to do so.</p>
                    <div><br /></div>
                    <p>Providing liquidity will get you LP Tokens, which will earn you rewards in the form of trading fees for making sure there's always liquidity for the exchange to use.</p>
                    <div><br /></div>
                    <p>Yield Farming</p>
                    <p>Yield farming lets users that are providing liquidity earn CHOCO rewards by locking their LP tokens into a smart contract. The incentive is to balance out the risk of impermanent loss that comes along with locking in your liquidity.</p>
                    <div><br /></div>
                    <p>Initial Farm Offerings (IFOs)</p>
                    <p>An Initial Farm Offering is an event that lets users buy into a limited-time offer to purchase new tokens. The IFO price is usually very generous.</p>
                  </div>
                  <div id="Swaps" class="tab-pane fade">
                    <h3>Token Swaps</h3>
                    <p>Token swaps on CharlieSwap are a simple way to trade one BEP-20 token for another via automated liquidity pools.</p>
                    <div><br /></div>
                    <p>When you make a token swap (trade) on the exchange you will pay a 0.25% trading fee, which is broken down as follows:</p>
                    <div><br /></div>
                    <p>0.17% - Returned to Liquidity Pools in the form of a fee reward for liquidity providers.</p>
                    <p>0.03% - Sent to the CharlieSwap Treasury.</p>
                    <p>0.05% - Sent towards CHOCO buyback and burn.</p>
                  </div>
                  <div id="Trade" class="tab-pane fade">
                    <h3>How to Trade</h3>
                    <p>Trading on CharlieSwap is very easy compared to most exchanges. You aren't going to be overwhelmed by charts or jargon, and calculations are all handled for you.</p>
                    <div><br /></div>
                    <p>Getting set up to trade</p>
                    <p>Before you can trade, you will need a Binance Smart Chain-compatible wallet. You can learn how to get one here.</p>
                    <p><br/></p>
                    <p><a href="/wallet-guide" rev="en_rl_none">Wallet Guide</a></p>
                    <div><br /></div>
                    <p>You will also need to have some BEP20 tokens to trade with. You can learn how to get some here.</p>
                    <div><br/></div>
                    <p><a href="/token-details" rev="en_rl_none">Bep20 Guide</a></p>
                    <div><br /></div>
                    <p>Trading on the CharlieSwap exchange</p>
                    <p>1. Go to the exchange page here.</p>
                    <div><br/></div>
                    <p><a href="/token-details" rev="en_rl_none">Bep20 Guide</a></p>
                    <div><br/></div>
                    <p>2. Unlock your Binance Smart Chain-compatible wallet by clicking Unlock Wallet (you can also Connect in the top right-hand corner). </p>
                    <p>3. Choose the token you want to trade from the dropdown menu in the "From" section. The default setting is BNB.</p>
                    <p>Whichever token you choose, you will need to make sure you have some to trade with. Your balance is shown above the token dropdown menu.</p>
                    <p>4. Choose the token you want to trade to in the "To" section as above. Next, type an amount for your "To" currency by clicking inside the input box.</p>
                    <p>Your "From" currency amount will be estimated automatically. You can also type your "From" amount and have the "To" amount estimate automatically if you like.</p>
                    <p>5. Check the details, and click the <span>Swap</span> button.</p>
                    <p>6. A window with more details will appear. Check the details are correct.</p>
                    <p>When you are ready, click the <span>Confirm Swap</span> button. Your wallet will ask you to confirm the action.</p>
                    <p>7. Done! You can click <span >View on BscScan</span> to see your transaction details on the explorer.</p>
                  </div>

                  <div id="Liquidity" class="tab-pane fade">
                    <h3>Liquidity Pools</h3>
                    <p>When you add your token to a Liquidity Pool you will receive Liquidity Provider (LP) tokens and share in the fees.</p>
                    <div><br /></div>
                    <h2>LP Tokens</h2>
                    <p >As an example, if you deposited <span >CHOCO</span> and <span >BNB</span> into a Liquidity Pool, you'd receive <span >CHOCO-BNB LP</span> tokens.</p>
                    <div><br /></div>
                    <p>The number of LP tokens you receive represents your portion of the CHOCO-BNB Liquidity Pool. </p>
                    <div><br /></div>
                   <p>You can also redeem your funds at any time by removing your liquidity.</p>
                   <div><br /></div>
                   <h2>Liquidity Providers earn trading fees</h2>
                   <p>Providing liquidity gives you a reward in the form of trading fees when people use your liquidity pool. </p>
                   <p>Whenever someone trades on CharlieSwap, the trader pays a 0.25% fee, <span >of which 0.17% </span>is added to the Liquidity Pool of the swap pair they traded on.</p>
                   <div><br /></div>
                   <p>For example:</p>
                   <ul className="whiterpaper_ul">
                     <li><p>There are 10 LP tokens representing 10 CHOCO and 10 BNB tokens.</p></li>
                     <li><p>1 LP token = 1 CHOCO + 1 BNB</p></li>
                     <li><p>Someone trades 10 CHOCO for 10 BNB.</p></li>
                     <li><p>Someone else trades 10 BNB for 10 CHOCO.</p></li>
                     <li><p>The CHOCO/BNB liquidity pool now has 10.017 CHOCO and 10.017 BNB.</p></li>
                     <li><p>Each LP token is now worth 1.00017 CHOCO + 1.00017 BNB.</p></li>
                     </ul>
                     <p>To make being a liquidity provider even more worth your while, you can also put your LP tokens to work whipping up some fresh yield on the CHOCO Farm, while still earning your 0.17% trading fee reward.</p>
                     <div><br /></div>
                     <h2>Impermanent Loss</h2>
                     <p>Providing liquidity is not without risk, as you may be exposed to impermanent loss.</p>
                     <div><br /></div>
                  </div>

                  <div id="Remove_add" class="tab-pane fade">
                    <h3>How to Add/Remove Liquidity</h3>
                   <p>"Liquidity" is central to how CharlieSwap's Exchange works. You can add liquidity for any token pair by staking both through the Liquidity page.</p>
                   <p>In return for adding liquidity, you'll receive trading fees for that pair, and receive LP Tokens you can stake in Farms to earn CHOCO rewards!</p>

                    <div><br /></div>
                    <h2>Adding liquidity</h2>

                    <p>To provide liquidity, you’ll need to commit an amount of any token pair you like. Your lowest value (in USD) of the two tokens will be the limit to the liquidity you can provide.</p>
                    <div><br /></div>
                    <p>You can easily trade for any tokens you need. Visit our How to Trade on CharlieSwap guide if you need to.
                      In this example, we will add liquidity using BNB and CHOCO.</p>
                    <main className="number_text_list">
                    <div>1. Visit the Liquidity page.</div>
                    <div>2. Click the <span >Add Liquidity</span> button.</div>
                    <div>3. For the top Input, leave BNB as it is.</div>
                    <div>4. For the bottom input, click ‘Select a currency’ and pick CHOCO.</div>
                    <div>5. Enter an amount on one of the tokens under “Input”. The other will calculate automatically.</div>
                    <div>(If your balance is too low on one pair enter a lower amount.)</div>
                    <div>6. Click the <span>Approve CHOCO </span>button. Your wallet will ask you to confirm the action.</div>
                    <div>7. The <span >Supply </span>button will light up. Click it.</div>
                    <div>8. A window will appear saying how much you will receive. Click the <span >Confirm Supply</span> button. Your wallet will ask you to confirm the action.</div>
                    <div>9. After a short wait you will see your LP Token balance at the bottom of the page.</div>

                    </main>
                    <div><br /></div>
                    <p>You can repeat the steps above to add more liquidity at any time.</p>
                    <div><br /></div>
                    <h2>Removing liquidity</h2>
                    <p>To remove liquidity.</p>
                    <div><br /></div>
                    <main className="number_text_list">
                    <div>1. Visit the Liquidity page.</div>
                    <div>2. Click on your pair under “Your Liquidity”.</div>
                    <div>3. Click<span > Remove</span>. A new window will appear.</div>
                    <div>4. Use the buttons or slider to choose what percent to remove. Choose <span >MAX</span> to remove everything.</div>
                    <div>5. Click <span >Approve</span>. Your wallet will ask you to confirm the action<span >.</span></div>
                    <div>6. The<span > Remove</span> button will light up. Click it.<div></div></div>
                    <div>7. A window will appear saying what you will receive. Click <span >Confirm</span>. Your wallet will ask you to confirm the action.</div>
                    <div>8. After a short wait you will see your new LP Token balance at the bottom of the page.</div>


                    </main>

                  </div>

                  <div id="Yield" class="tab-pane fade">
                    <h3>Yield Farming</h3>
                    <p>Yield Farms allow users to earn CHOCO while supporting CharlieSwap by staking LP Tokens.</p>
                     <p>Check out our How to Use Farms guide to get started with farming.</p>
                     <div><br /></div>
                     <p>Yield farming can give better rewards than CACAO Pools, but it comes with a risk of <span >Impermanent Loss</span>. It’s not as scary as it sounds, but it is worth learning about the concept before you get started.</p>

                    <div><br /></div>
                    <h2>Reward calculations</h2>
                     <p>Yield Farm APR calculation includes both the rewards earned through providing liquidity and rewards earned staking LP Tokens in the Farm.</p>
                    <div><br /></div>
                     <p>Previously, rewards earned by LP Token-holders generated from trading fees were not included in Farm APR calculations. APR calculations now include these rewards, and better reflect the expected APR for Farm pairs.</p>
                    <div><br /></div>
                   <p>Below is a basic explanation of how APR is calculated.</p>
                    <div><br /></div>
                   <p> The WBNB/BUSD pair, we see these values:</p>
                    <p><span >Liquidity:</span> $387.42M</p>
                    <p><span >Volume 24H:</span> $96.97M</p>
                    <p><span >Volume 7D:</span> 709.73M</p>
                    <div><br /></div>
                    <p>To calculate the APR, first we take the 24hour volume, $96,970,000, and calculate the fee-share of LP-holders, 0.17% [<span >$96,970,000*0.17/100 = $164,849]</span>.</p>
                    <div><br /></div>
                    <p>Next, we estimate the yearly fees based on the 24h volume [<span >$164,849*365 = $60,169,885</span>].</p>
                    <div><br /></div>
                    <p>Now we can calculate the fee APR with yearly fees divided by liquidity [(<span >$60,169,885/$387,420,000)*100 = 15.53%</span>]</p>
                     <div><br /></div>
                    <p>With the fee APR, we can add the fee APR (15.53%) and the Farm staking APR (20.08%) to get the new total APR [<span >15.53%+20.08% = 35.61%</span>].</p>
                  </div>
                  <div id="Farms_a" class="tab-pane fade">
                   <h3>How to Use Farms</h3>
                    <div><br /></div>
                   <p>Yield Farming in Farms is a great way to earn CHOCO rewards on CharlieSwap.</p>
                    <div><br /></div>
                    <p>Unlike CACAO Pools, Farms require you to stake <span>two tokens</span> to get LP Tokens, which you then stake in the Farm to earn rewards. This lets you earn CHOCO while still keeping a position in your other tokens!</p>
                    <div><br /></div>
                    <p>Yield farming can give better rewards than CACAO Pools, but it comes with a risk of <span >Impermanent Loss</span>. It’s not as scary as it sounds, but it is worth learning about the concept before you get started.</p>
                    <p>Check out this great <a href="https://academy.binance.com/en/articles/impermanent-loss-explained" >article about Impermanent Loss </a>from Binance Academy to learn more.</p>
                    <div><br /></div>
                    <h2>Getting prepared</h2>
                    <p>Yield farming takes a little work to get set up.</p>
                    <p>You’re going to need some "LP Tokens" to enter into a Farm with. Farms can only accept their own exact LP Token; for example, the CHOCO-BNB Farm will only accept CHOCO-BNB LP Tokens.</p>
                    <div><br /></div>
                    <p>To get the exact LP Token, you'll need to provide liquidity for that trading pair. So to get CHOCO-BNB LP Tokens, you'll first have to provide liquidity for the CHOCO-BNB pair.</p>
                    <div><br /></div>
                    <p>It may sound intimidating, but it's not too complicated. Let's go through step by step.</p>
                    <div><br /></div>
                    <h2>Finding your Farm</h2>
                    <p>Before you proceed, you'll want to choose a Farm that's right for you.Visit the Farms page and you’ll see a list of available Farms.</p>
                    <div><br /></div>

                    <p>CHOCO-BNB and BUSD-BNB are pinned to the top of the list with the default <span>Hot</span> sorting. After the pinned Farms, Hot will show other Farms in order of how new they are.</p>
                    <div><br /></div>
                    <p>You can choose another sorting option if you like, such as by <span >APR</span> for the Farms with the highest reward rate currently.</p>
                    <div><br /></div>
                    <p>When you find a Farm that you'd like to use, note down the trading pair, e.g. BNB-CHOCO in case you need it later.</p>
                    <div><br /></div>

                    <h2>Providing liquidity to get LP Tokens</h2>
                    <p>Now that you've found a Farm to stake in, you will need to add liquidity to get your LP Tokens.</p>
                    <main className="number_text_list">
                    <div>1. Click on the row of the Farm you've chosen from the list. It will open to show more details.</div>
                    <div>2. On the left, you'll see some links. Click the <span>Get (your pair) LP</span> link.</div>
                    </main>
                    <p>This will open the Add Liquidity page for your Farm's pair. We have a guide to adding liquidity you can follow to get your LP Tokens.</p>
                    <h2>Putting your LP Tokens into a farm</h2>
                    <p>Now that you have your LP Tokens, you’re ready to start staking them in a Farm and earning rewards!</p>
                    <p>Putting your LP Tokens into the Farm</p>
                    <main className="number_text_list">
                    <div>1.Go back to the Farms page and locate your Farm. Click anywhere on the row showing your pair. It will expand to show more details.</div>

                    </main>
                    <p>When you’re ready, click the <span >Enable</span> button and confirm your action in your wallet.</p>
                    <main className="number_text_list">
                    <div>1.Go back to the Farms page and locate your Farm. Click anywhere on the row showing your pair. It will expand to show more details.</div>

                    </main>
                    <p>Type the amount of LP Tokens you would like to farm with into the field, or just click <span >Max</span> to use all of your LP Tokens.</p>
                    <main className="number_text_list">
                    <div>1.When you have the amount entered, the <span>Confirm</span> button will light up. Click it. Your wallet will ask you to confirm your action.</div>
                    <div>2.After a short wait, the window will close, and you will see your new staked LP Token balance in the details.</div>
                    </main>
                    <h2>Adding or removing LP Tokens from a Farm</h2>
                     <p>You may decide you would like to add more LP Tokens to a Farm at a later date, or to take some out of a Farm. You can do this very easily whenever you'd like.</p>
                     <main className="number_text_list">
                     <div>1. Return to the Yield Farms page. At the top of the page you'll see a <span >Staked only</span> toggle. Click the toggle.</div>
                     <div><br /></div>
                     <div>2. Find the Farm you have LP Tokens in, and click the row to view details. You will see a <span>-</span> and <span>+</span> button on the right-hand side. Click <span >-</span> to remove LP Tokens, or <span >+</span> to add more LP Tokens.</div>
                     <div>3. A window will open that looks like the one you used earlier to first stake your LP Tokens. Like last time, type the amount you would like to unstake/stake, or click <span >Max</span> to remove/add all available LP Tokens.</div>
                     <div>4. Make sure your information is correct. When you are ready, click the <span >Confirm</span> button and confirm the action in your wallet.</div>
                     <div>After a short wait, your new balance will show in the details section of your LP Token pair. If you've unstaked your LP Tokens, any unharvested rewards you had will automatically have been collected.</div>

                    </main>
                    <h3>Collecting your farming rewards</h3>
                    <p>Farming will bring you CHOCO rewards over time. You can collect these rewards and use them to get more LP Tokens, stake them in CACAO Pools, use them to play Lottery, or anything else you’d like.</p>
                    <h2>Returning to your Farm to Harvest</h2>
                    <p>You can harvest your Farm and CACAO Pool rewards together from the Home page. If you'd like to collect your farming rewards only, follow along.</p>
                    <p>To collect your rewards, you’ll need to visit your chosen Farm and collect the CHOCO waiting for you.</p>
                    <main className="number_text_list">
                    <div>1. Return to the Farms page.</div>
                    <div>2. Find the Farm you staked your LP Tokens in, and click the row to view details. You should see an estimate of your rewards under “CHOCO earned”.</div>
                    <div>3. Click the <span >Harvest</span> button and confirm the action in your wallet. After a short wait, the CAKE will be claimed to your wallet for you to use as you like.</div>
                     <div>4. Make sure your information is correct. When you are ready, click the <span >Confirm</span> button and confirm the action in your wallet.</div>
                     <div>After a short wait, your new balance will show in the details section of your LP Token pair. If you've unstaked your LP Tokens, any unharvested rewards you had will automatically have been collected.</div>

                    </main>
                    <h3>How often should I harvest my rewards?</h3>
                    <p>How often you harvest your rewards is up to you, but it does help to remember that there is a small fee involved in harvesting.</p>
                    <div><br /></div>
                    <p>You can see this fee in your wallet when confirming after clicking <span>Harvest</span>.</p>
                    <div><br /></div>
                    <p>This shows the fee for harvesting as it appears in the MetaMask wallet. Different wallets will show the information a little differently. Consider leaving your rewards to grow for a while so you pay fees less often.</p>
                    <div><br /></div>
                      <p>And that's all there is to it!</p>
                      <p>Happy farming!</p>
                  </div>
                  <div id="CACAO" class="tab-pane fade">
                    <h3>CACAO Pools</h3>
                    <h2>What are CACAO Pools?</h2>
                    <p>CACAO Pools are the simplest way to earn free tokens on PancakeSwap. Stake CHOCO, earn free tokens. It’s really that easy.</p>
                    <p>Some special pools let you stake other tokens besides CHOCO, too!</p>
                  </div>
                  <div id="Stake" class="tab-pane fade">
                    <h3>How to Stake in CACAO Pools</h3>
                    <div><br /></div>
                    <p>Staking in CACAO Pools lets you earn CHOCO or other tokens while you sleep!</p>
                    <div><br /></div>
                    <p>It's simpler than farming with CharlieSwap's Yield Farms, because unlike the Farms, you only need to stake <span>one token</span> to start earning: usually CHOCO.</p>
                    <div><br /></div>
                   <h2>Getting started with CACAO Pool staking</h2>

                   <main className="number_text_list">
                    <div>1. Go to the Pools page.</div>
                    <div>2. Connect to your Binance Smart Chain-compatible wallet by clicking the <span>Connect</span> button (top right-hand side)..</div>
                    <div>3. Click <span>Unlock Wallet</span>.</div>
                    <div>4. Choose which CACAO Pool you want to stake in.</div>


                    </main>
                    <div><br /></div>
                    <p><span>Manual CAKE</span> doesn’t automatically compound like Auto CHOCO. You’ll need to harvest and compound your earnings manually.</p>
                    <div><br /></div>
                    <p>Other CACAO Pools let you stake your CHOCO to earn dozens of other cool tokens. Make sure you check them out.  </p>
                    <main className="number_text_list">
                    <div>5. Once you choose a pool, click the <span>Enable</span> button. Your wallet will ask you to confirm the action.</div>
                    <div>6. The Enable button should now be replaced with <span>Stake</span>. Click the button to bring up the staking menu.</div>
                    <div>7. Type in an amount or slide the rabbit to choose how much CHOCO you want to stake.</div>
                    <div>8. Click <span>Confirm</span>. Your wallet will ask you to confirm the action.</div>
                    <div>9. The Confirm button will change to "Confirming", then once the transaction is successful, you’ll see your staked amount change and the button will change again.</div>
                    <div>10. You should now be able to see details on your CACAO Pool. Auto CHOCO will show a countdown until the unstaking fee ends. The other pools will show a <span>Collect</span> button to claim your staking rewards into your wallet.</div>

                    </main>
                    <div><br /></div>
                    <h2>Adding and removing CAKE from a pool</h2>
                      <p>You can easily  add more CHOCO to a Syrup Pool, or move some CHOCO to a more profitable pool. Here’s how you can move your staked CHOCO.</p>
                      <main className="number_text_list">
                    <div>1. Click the - to take some CHOCO out of a pool, or the + to add more to the pool.</div>
                    </main>
                    <div><br /></div>
                    <p>Please note that unstaking from the Auto CHOCO pool has a fee of 0.1% if you unstake within 72 hours.</p>
                    <main className="number_text_list">
                    <div>2. A window will open. If you clicked the +, choose the amount you would like to add to the pool. If you clicked the -, choose the amount to take out of the pool.</div>
                    <div>3. Click Confirm.</div>
                    <div>4. After a little wait, you'll be able to see your new amount of staked tokens.</div>
                    </main>
                  </div>
                </div>


              </GridItem>
              </GridContainer>






        </div>

        <FooterHome />
      </div>
    </div>
  );
}
