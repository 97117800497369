import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import FooterHome from "components/Footer/FooterHome.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksToken from "components/Header/HeaderLinksToken.js";
import styled from "styled-components";
import { toastAlert } from "../helper/toastAlert";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import config from "../lib/config";
import ABI from "../ABI/charlieicoabi.json";
import BUSD from "../ABI/busdabi.json"

const busdContractAddr = config.busdcontract;
const contractAddr = config.charlieicocontract;

const dashboardRoutes = [];

const providerOptions = {
  connector: async (ProviderPackage, options) => {
    const provider = new ProviderPackage(options);
    await provider.enable()
    return provider;
  }
};

const provider = new WalletConnectProvider({
  rpc: {
    97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    56: "https://bsc-dataseed.binance.org/",
  },
});

const web3Modal = new Web3Modal({
  //  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

const useStyles = makeStyles(styles);
let slider1;
let slider2;
export default function TokenDetails(props) {

  const [expanded, setExpanded] = React.useState('panel1');
  const [currAddress, setcurrentAddress] = React.useState('');
  const [accept, setaccept] = React.useState(false);
  const [coinvalue, setcoinvalue] = React.useState(0);
  const [charlievalue, setcharlievalue] = React.useState(0);
  const [coin, setcoin] = React.useState('BUSD');
  const [charlieusd, setcharlieusd] = React.useState(0);
  const [show, setshow] = React.useState(false)
  const [userbnbbal, setuserbnbbal] = React.useState(false)
  const [userbusdbal, setuserbusdbal] = React.useState(false)
  const [progress, setprogress] = React.useState(false)

  const classes = useStyles();

  const { ...rest } = props;

  /*------------developer code starts---------------------------------------*/

  async function alertconnect() {
    toastAlert('error', "Connect Wallet", 'excerciseerr');
  }

  async function checkchange(termsandcon) {
    setaccept(termsandcon);
  }

  async function charliechange(value) {
    setcharlievalue(value);
    console.log(value, "========valvalval1char")
    if (value != "" && value != undefined && value != null && parseFloat(value) > 0) {
      if (coin == "BUSD") {
        var usd2 = (parseFloat(value) / parseFloat(charlieusd));
        setcoinvalue(usd2.toFixed(5));
      }
    } else {
      setcoinvalue(0);
    }
  }

  async function inputchange(value) {
    setcoinvalue(value);
    console.log(value, "========valvalval1bnb")
    console.log(coin, "====================================coin")
    if (value != "" && value != undefined && value != null && parseFloat(value) > 0) {
      if (coin == "BUSD") {
        var trxtousdt = (parseFloat(value) * parseFloat(charlieusd));
        setcharlievalue(trxtousdt.toFixed(5));
      }
    } else {
      setcharlievalue(0)
    }
  }

  async function selectchange(e) {
    console.log(e.target.value)
    setcoin(e.target.value)
  }

  async function getcoinvalue() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== 'undefined') {
          const web3 = new Web3(window.web3.currentProvider)
          if (window.web3.currentProvider.isMetaMask === true) {
            var charlieContract = new web3.eth.Contract(ABI, contractAddr);
            var tokenvalue = await charlieContract.methods.getTokenprice().call();
            console.log(tokenvalue, "========tokentoken")
            var tokenusd = parseFloat(tokenvalue) / 1000000
            setcharlieusd(tokenusd)
          }
        } else {
          toastAlert('error', "Please Add Metamask External", 'excerciseerr');
        }
      } catch (err) {
        toastAlert('error', "Please Add Metamask External", 'excerciseerr');
      }
    }
    else {
      toastAlert('error', "Please Add Metamask External", 'excerciseerr');
    }
  }

  async function getbalance(add) {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    var busdContract = new web3.eth.Contract(BUSD, busdContractAddr);
    var busdBln = await busdContract.methods.balanceOf(add).call();
    console.log('tokenDethBln', busdBln)
    var value = parseFloat(busdBln) / 1000000000000000000;
    console.log("dethbalance:", busdBln)
    setuserbusdbal(value)
  }

  async function buyfunction() {
    setprogress(true)
    if (typeof localStorage.getItem('account') !== 'undefined' && localStorage.getItem('account') !== "" && localStorage.getItem('account') !== null && localStorage.getItem('account') !== undefined) {
      var currAddress = localStorage.getItem('account');
      if (typeof window.web3 != "undefined" && window.web3.currentProvider != 'undefined') {
        if (window.web3.currentProvider.networkVersion != 97) {
          toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
          setprogress(false)
        } else {
          const provider = await web3Modal.connect();
          const web3 = new Web3(provider);
          if (coinvalue != null && coinvalue != undefined && parseFloat(coinvalue) > 0 && coinvalue != "") {
            if (charlievalue != null && charlievalue != undefined && parseFloat(charlievalue) > 0 && charlievalue != "") {
              if (coin == "BUSD") {
                if (parseFloat(coinvalue) < parseFloat(userbusdbal)) {
                  if (accept == true) {
                    var contract = new web3.eth.Contract(ABI, contractAddr);
                    var budcontrct = new web3.eth.Contract(BUSD, busdContractAddr);
                    console.log(contractAddr, (parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString(), "======input")
                    await budcontrct.methods.approve(contractAddr, (parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString())
                      .send({ from: currAddress })
                      .then(async function (result1, error1) {
                        if (result1) {
                          await contract.methods.deposit((parseInt(parseFloat(coinvalue) * config.decimalvalues)).toString())
                            .send({ from: currAddress })
                            .then(async function (result, error) {
                              console.log(result, error, "======================getgetget")
                              if (result) {
                                setprogress(false);
                                toastAlert('success', "Successfully completed", 'excerciseerr');
                                setcoinvalue(0)
                                setcharlievalue(0)
                              }
                            }).catch(function (error) {
                              setprogress(false)
                              toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                              console.log(error, "======================error")
                            })
                        } else {
                          setprogress(false)
                          toastAlert('error', "Error Occured Please Try Again Later", 'excerciseerr');
                        }
                      })
                  } else {
                    setprogress(false)
                    toastAlert('error', "Please accept Terms and Condition", 'excerciseerr');
                  }
                } else {
                  setprogress(false)
                  toastAlert('error', "Insufficient Balance", 'excerciseerr');
                }
              }
            } else {
              setprogress(false)
              toastAlert('error', "Invalid Input", 'excerciseerr');
            }
          } else {
            setprogress(false)
            toastAlert('error', "Invalid Input", 'excerciseerr');
          }
        }
      } else {
        setprogress(false)
        toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
      }
    } else {
      setprogress(false)
      toastAlert('error', "Connect BSC Testnet", 'excerciseerr');
    }
  }

  return (
    <div className="header_home">
      <Header
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinksToken />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "white",
        }}
        {...rest}
      />



      <div className="main whitepaper_section">
        <div className={classes.container + " homepage_container"} >
          <GridContainer className="mt-5 align-items-flex-start">
            <GridItem sm={12} md={10} lg={12} className=" text-center" data-aos="fade-up" data-aos-duration="2000">
              <div class="tab-content token_content">
                <div id="Intro" class="tab-pane fade in active show">
                  <h3>Get BEP20 Tokens</h3>
                  <p className="mb-4">The native tokens of Binance Smart Chain (BSC) are BEP20</p>
                  <p className="mb-4">To do most things on BSC, you will need to pay gas, which comes in the form of BEP20 BNB.</p>
                  <p className="mb-4">You will also need the BEP20 version of any token you want to trade, stake etc. on BSC.</p>
                  <p  className="mb-4">You can go a few ways about converting your tokens to BEP20, but here are the ones we recommend:</p>
                  <nav>
                    <div class="nav nav-tabs p-0" id="nav-tab" role="tablist">
                      <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#binance-bridge" role="tab" aria-controls="nav-home" aria-selected="true">🌉 Binance Bridge</a>
                      <a class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#binance-com" role="tab" aria-controls="nav-profile" aria-selected="false">💰 Binance.com</a>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="binance-bridge" role="tabpanel" aria-labelledby="nav-home-tab">
                      <p className="mb-4 mt-4"><a href="https://www.binance.org/en/bridge" target="_blank"><b>Binance Bridge</b></a> - You don't need an account, or to register etc.</p> 
                      <p><a href="https://docs.binance.org/smart-chain/guides/bridge-v2.html" target="_blank">📖 Here's a quick guide they made to help you out.</a></p>
                    </div>
                    <div class="tab-pane fade" id="binance-com" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <p className="mb-4 mt-4"><b>Binance</b> - You can withdraw tokens as BEP20 via your Binance account (if you have one). Bear in mind you need an account to do so.</p> 
                      <p><a href="https://www.binance.com/en/support/faq/85a1c394ac1d489fb0bfac0ef2fceafd" target="_blank">📖 Here's a quick guide they made to help you out.</a></p>
                    </div>
                  </div>                  
                </div>
              </div>
            </GridItem>
          </GridContainer>






        </div>

        <FooterHome />
      </div>
    </div>
  );
}
